/// <reference path="./../../../../typings/tsd.d.ts"/>

export class FindController {
	static $inject = ['$location', '$scope', '$state', '$log', 'switchBoard'];

	public vm;

	constructor(private $location, $scope, $state, private $log, switchBoard ) {
		this.vm = $scope;

		$state.transitionTo("home");
		$scope.search = function() {
			$state.transitionTo("results", {
				searchText: $scope.searchText
			}, {
					reload: true,
					notify: true
        });
		}

		// Set our view title directly to avoid
		// issues with earlier IE versions;
		window.document.title = 'EMEA Neo4j SuperSearch';

		// Update our body class without Angularjs;
		// Use jQuery to remove (best speed), and
		// standard Javascript to add (best perf again);
		$('body').removeClass();
		document.body.classList.add('find');

		// Primary collection to search; defaults to Contacts;
		this.vm.findTypes =
			[
				{
					id: 0,
					value: 'Company',
					phrase: 'Companies',
					icon: 'ico-company',
					find: 'company'
				},
				{
					id: 1,
					value: 'Company_Contact',
					phrase: 'Contacts',
					icon: 'ico-contact',
					find: 'contact'
				}
			];

		if (!_.isUndefined(switchBoard.getFindType())) {
			var res = [];

			this.vm.findTypes.forEach(
				function(o) {
					if (o.value == switchBoard.getFindType()) res.push(o);
				});

			this.vm.selectedFindType = res[0];
		} else {
			this.vm.selectedFindType = this.vm.findTypes[1];
		}

		// Country Filter;
		this.vm.ddSelectOptions = [
			{
			id:        0,
			text:     'All Countries',
			value:    'All',
			cssClass: 'glyph-cdd-all',
			status:   'all countries'
			},
			{
			id:       1,
			text:     'United Kingdom',
			value:    'United Kingdom',
			cssClass: 'glyph-cdd-uk',
			status:   'the United Kingdom only'
			}
		];

		// Default to the United Kingdom;
		if (_.isUndefined(switchBoard.getFindCountry())) {
			$scope.ddSelectSelected = $scope.ddSelectOptions[1];
		} else {
			$scope.ddSelectSelected = $scope.ddSelectOptions[switchBoard.getFindCountry()];
		}

		// Typeahead;
		function idIndex(a,id) {
			for (var i=0;i<a.length;i++)
				{
				if (a[i].id == id)
					{
					return i;
					}
				}
			return null;
		}

		this.vm.filters = {};

		var typeaheadData;

		this.vm.$watchGroup(
			[
				'userLanguage.langChoice',
				'selectedFindType.value',
				'selected',
				'ddSelectSelected.id'
			],
			function(newValues) {
				switchBoard.setUserLanguage(newValues[0]);
				switchBoard.setFindType(newValues[1]);
				switchBoard.setFindText(newValues[2]);
				switchBoard.setFindCountry(newValues[3]);
				console.log('printSettings: ' + switchBoard.getUserLanguage() + ', ' + switchBoard.getFindType() + ', ' + switchBoard.getFindText() + ', ' + switchBoard.getFindCountry() );
			});

		this.vm.$watchGroup(
			['selectedFindType.value', 'ddSelectSelected.value', 'selected'],
			function(newValues, oldValues, scope) {
				updateTypeAheadList(newValues[0],newValues[1],newValues[2]);
			});

		function updateTypeAheadList(anEntityType,aCountry,aTextInput) {
			var qry = constructTypeaheadQuery(anEntityType,aCountry,aTextInput);
			fetchTypeaheadList(qry);
		}

		function constructTypeaheadQuery(anEntityType,aCountry,aTextInput) {
			var pQuery,
			    pInput = (!aTextInput) ? '.*' : ('.*' + aTextInput + '*');

			if (anEntityType === 'Company')
				{
				pQuery = {"statements":[{"parameters":{"cntry": aCountry,"anInp":pInput},"statement":"MATCH (c:Company) WHERE (c.Country =~ {cntry}) AND (c.CompanyName =~ {anInp}) RETURN c ORDER BY c.CompanyName LIMIT 100","resultDataContents":["graph"]}]};
				}
			else
				{
				pQuery = {"statements":[{"parameters":{"cntry": aCountry,"anInp":pInput},"statement":"MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact) WHERE c.Country =~ {cntry} AND (cc.LastName =~ {anInp} OR cc.FirstName =~ {anInp}) RETURN r ORDER BY cc.LastName LIMIT 100","resultDataContents":["graph"]}]};
				}

			return pQuery;
		}

		function createTypeaheadForCompanies(data) {
		 	var nodes = [],
			    links = [],
			    nmes  = [];

			data.results[0].data.forEach(function (row) {
				row.graph.nodes.forEach(function (n) {
					if (idIndex(nodes,n.id) == null && n.labels[0] == 'Company')
						{
						nmes.push(
							{
							id:n.id,
							type:n.labels[0],
							name:n.properties.CompanyName,
							country:n.properties.Country
							});
						}
					});
			});

		$scope.states = nmes;
		}

		function createTypeaheadForCompanyContacts(data) {
		 	var nodes = [],
			    links = [],
			    nmes  = [];

			data.results[0].data.forEach(function (row) {
				row.graph.nodes.forEach(function (n) {
					if (idIndex(nodes,n.id) == null && n.labels[0] == 'Company_Contact')
						{
						nmes.push(
							{
							id:n.id,
							type:n.labels[0],
							company:n.properties.CompanyName,
							name:n.properties.LastName + ', ' + n.properties.FirstName
							});
						}
					});
			});

		$scope.states = nmes;
		}

		function fetchTypeaheadList(aQuery)
			{
			// Fetch TypeAhead List for either company names or company contacts;
			var auth = "Basic " + btoa("neo4j:mnbv!456");

			// Note: Typescript expects a very specific order for Ajax call;
			$.ajax({
				url: "http://jllcontactnet.cloudapp.net:7474/db/data/transaction/commit",
				headers: {"Authorization":auth},
				dataType: 'json',
				type: 'POST',
				data: JSON.stringify(aQuery),
				contentType:"application/json; charset=utf-8",
				success: function(data, textStatus, jqXHR) {
					if ($scope.selectedFindType.value === 'Company')
						{
						createTypeaheadForCompanies(data);
						}
					else
						{
						createTypeaheadForCompanyContacts(data);
						}
				}
			});
		}

		// Default model state for the selected search text;
		if (_.isUndefined(switchBoard.getFindText())) {
			this.vm.selected = undefined;
		} else {
			this.vm.selected = switchBoard.getFindText();
		}


		// Define language selection model;
		this.vm.userLanguage = {};
		this.vm.userLanguage.availableLanguages = [{
			id: "1",
			code: 'de-DE',
			name: "Deutsch"
		}, {
				id: "2",
				code: 'en-GB',
				name: "English"
			}, {
				id: "3",
				code: 'fr-FR',
				name: "Français"
			}];

		// Default model state for language;
		//this.vm.userLanguage.langChoice = "2";

		if (_.isUndefined(switchBoard.getUserLanguage())) {
			// Default to English;
			this.vm.userLanguage.langChoice = "2";
		} else {
			this.vm.userLanguage.langChoice = switchBoard.getUserLanguage();
		}


		this.vm.countryClick = function() {
			$('.dropdown-menu').addClass('ng-hide');
		}

	this.activate();
	}

	public countryClick()
		{
		this.vm.countryClick();
		}

	public activate() { }
}

angular
	.module('app.find', [])
	.controller('findController', FindController);